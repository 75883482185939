<template>
  <div>
    <v-row class="d-flex">
      <v-col cols="12">
        <site-filter store="premium" :dependsOnSiteGroups="false" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import SiteFilter from "@/components/common/filters/SiteFilter";

export default {
  name: "Filters",
  components: {
    SiteFilter,
  },
  data() {
    return {};
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  async created() {
    const {
      data: { items },
    } = await this.axiosGet("/site-groups/", {
      search: "Report Weekly Email",
    });

    await this.$store.dispatch(
      "premium/updateSiteGroups",
      items.map((item) => item.id)
    );
  },
  methods: {},
  computed: {},
};
</script>

<style></style>
